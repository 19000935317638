import { Projects } from './projects'

export enum AuthUsers {
  enthusiast = 'enthusiast',
  instructor = 'instructor'
}

export type AuthUsersType = keyof typeof AuthUsers

export const AuthOnProjects: {
  [keys in AuthUsersType]: {
    id: AuthUsersType
    projects: (keyof typeof Projects)[]
  }
} = {
  enthusiast: {
    id: 'enthusiast',
    projects: ['eola', 'widget']
  },
  instructor: {
    id: 'instructor',
    projects: ['eola', 'business', 'widget']
  }
}
